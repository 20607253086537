<template>
  <div class="repairs">

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialogTicket" max-width="1100px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>New</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>

              <v-layout row wrap>
                <v-flex xs3>
                  <v-text-field
                    v-model="ttn.number"
                    label="number"
                    prepend-icon="mdi-archive-outline"
                  ></v-text-field>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    v-model="ttn.phone"
                    label="phone"
                    prepend-icon="mdi-cellphone-basic"
                  ></v-text-field>
                </v-flex>

                <v-flex xs4>
                  <v-autocomplete
                    label="incoming from the customer"

                    hide-details="auto"
                    disabled

                    prepend-icon="mdi-swap-horizontal"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs1>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="pa-3 ">
                        <v-btn
                          v-if="Object.keys(ttn.data).length === 0"
                          :disabled="!ttn.number || !ttn.phone"
                          @click="searchTTN"
                          color="primary"
                        >
                          <v-icon color="white">mdi-magnify</v-icon>
                        </v-btn>

                        <v-icon v-else large color="primary">mdi-check</v-icon>
                      </div>
                    </template>
                    <span>Get TTN Data</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>

              <v-divider class="ma-7" inset></v-divider>

              <v-layout row wrap>
                <v-flex xs12>
                  <v-autocomplete
                    v-model="contractor_selected"
                    :items="contractors"
                    item-text="name_complete"
                    item-value="id"
                    label="contractor"
                    prepend-icon="mdi-text-box-multiple"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs4>
                  <v-text-field
                    v-model="customer.pib"
                    label="pib"
                    prepend-icon="mdi-account-details-outline"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="customer.phone"
                    label="phone"
                    prepend-icon="mdi-cellphone-basic"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="customer.email"
                    label="e-mail"
                    prepend-icon="mdi-email-open-outline"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-divider class="ma-7" inset></v-divider>

              <v-layout row wrap
                v-for="(obj, index) in parcel_box"
                :key="`extra-${index}`"
              >

                <v-flex xs1 class="pl-2">{{index+1}}.</v-flex>

                <v-flex xs3 class="pr-2">
                  <v-autocomplete
                    v-model="obj.object"
                    :items="repair_objects"
                    item-text="serial"
                    item-value="id"
                    label="object"
                    hide-details="auto"
                    clearable
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs2 class="pr-2">
                  <v-text-field
                    v-if="!obj.object"
                    v-model="obj.imei"
                    label="serial new"
                    hide-details="auto"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-else
                    :value="obj.object.serial"
                    label="serial"
                    hide-details="auto"
                    outlined

                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 class="pr-2">
                  <v-autocomplete
                    v-if="!obj.object"
                    v-model="obj.prod_id"
                    :items="devices"
                    item-text="name"
                    item-value="id"
                    label="device new"
                    hide-details="auto"
                    outlined
                    dense
                  ></v-autocomplete>
                  <v-text-field
                    v-else
                    :value="obj.object.name"
                    label="device"
                    hide-details="auto"
                    outlined

                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs3 class="pr-2">
                  <v-file-input
                    v-model="obj.file"
                    label="act"

                    truncate-length="15"
                    outlined
                    dense

                    multiple
                  ></v-file-input>
<!--                  show-size counter multiple-->
                </v-flex>

                <v-flex xs1 class="align-self-center d-flex justify-center pb-5">
                  <v-icon @click="addDevice(obj, parcel_box)">mdi-plus</v-icon>
                  <v-icon
                      v-show="parcel_box.length > 1"
                      @click="removeDevice(index, parcel_box)"
                    >mdi-delete-outline
                  </v-icon>
                </v-flex>

              </v-layout>

            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn
              text
              @click="saveTicketsFiles"
              color="green darken-3"
            >save</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>
    <v-dialog v-model="dialogTTN" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>New TTN</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>

              <v-layout row wrap>
                <v-flex xs3>
                  <v-text-field
                    v-model="ttn.number"
                    label="ttn"
                    prepend-icon="mdi-archive-outline"
                  ></v-text-field>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    v-model="ttn.phone"
                    label="phone"
                    prepend-icon="mdi-cellphone-basic"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-autocomplete
                    v-model="ttn.direction"
                    label="direction"

                    :items="directions"
                    item-text="name"
                    return-object

                    hide-details="auto"
                    :disabled="Object.keys(ttn.data).length === 0"

                    prepend-icon="mdi-swap-horizontal"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs1>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="pa-3 ">
                        <v-btn
                          v-if="Object.keys(ttn.data).length === 0"
                          :disabled="!ttn.number || !ttn.phone"
                          @click="searchTTN"
                          color="primary"
                        >
                          <v-icon color="white">mdi-magnify</v-icon>
                        </v-btn>

                        <v-icon v-else large color="primary">mdi-check</v-icon>
                      </div>
                    </template>
                    <span>Get TTN Data</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>

            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn
              :disabled="Object.keys(ttn.data).length === 0 || !ttn.direction"
              @click="saveTTN"
              color="green darken-3"
              text
            >save</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>
        
<!--Buttons-->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                @click="dialogTicket=true"
                color="primary"
                :disabled="serial_selected === null"
              >
                <v-icon color="white">mdi-calendar-plus-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>New Repair Ticket</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                @click="dialogTTN=true"
                color="primary"
                :disabled = "selected.length === 0"
              >
                <v-icon color="white">mdi-cube-send</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Add TTN</span>
        </v-tooltip>

        <v-spacer></v-spacer>

<!--Filters-->
<!--        <v-flex xs2 lg2 xl2>-->
<!--          <v-autocomplete-->
<!--            v-model="contractor_selected"-->
<!--            :items="filter_datas.contractors"-->
<!--            item-text="name"-->
<!--            item-value="id"-->
<!--            hide-selected-->
<!--            clearable-->
<!--            label="contractor"-->
<!--            prepend-icon="mdi-code-block-braces"-->
<!--            return-object-->
<!--          ></v-autocomplete>-->
<!--        </v-flex>-->
<!--        <v-flex xs2 lg2 xl2>-->
<!--          <v-autocomplete-->
<!--            v-model="serial_selected"-->
<!--            :items="filter_datas.imeis"-->
<!--            item-text="serial_device"-->
<!--            item-value="id"-->
<!--            hide-selected-->
<!--            clearable-->
<!--            label="imei (serial)"-->
<!--            prepend-icon="mdi-code-block-braces"-->
<!--            return-object-->
<!--          ></v-autocomplete>-->
<!--        </v-flex>-->
<!--        <v-flex xs2 lg2 xl2>-->
<!--          <v-autocomplete-->
<!--            v-model="device_selected"-->
<!--            :items="filter_datas.devices"-->
<!--            item-text="name"-->
<!--            item-value="id"-->
<!--            hide-selected-->
<!--            clearable-->
<!--            label="device"-->
<!--            prepend-icon="mdi-code-block-braces"-->
<!--            return-object-->
<!--          ></v-autocomplete>-->
<!--        </v-flex>-->

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="tickets"

        show-select

        show-expand
        single-expand
        :expanded.sync="expanded"

        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"

        :server-items-length="totalTickets"

        :loading="load"
        loading-text="Repair Tickets loading... Please wait"

        mobile-breakpoint="0"
        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan='headers.length'>
            <v-container>
              <v-card>
                <v-card-title>
                  <v-tabs v-model='tab' right height="35">
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab v-for="t in tabs" :key="t.id">
                      <v-icon left>{{ t.icon }}</v-icon>
                      {{ t.name }}
                    </v-tab>
                  </v-tabs>
                </v-card-title>
                <v-card-text>
                  <v-tabs-items v-model="tab">

                    <v-tab-item key="0">
                      <v-card>
                        <v-card-title>
                          <v-icon color="primary">mdi-car-outline</v-icon>
                          <v-divider class="mx-4" vertical></v-divider>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text>
                            <v-row>

                              <v-col class="d-flex flex-column">
                                <v-card>
                                  <v-card-title>
                                    Device
                                  </v-card-title>
                                  <v-card-text>
                                    buy: {{ item.data_device.purchase_date }}
                                  </v-card-text>
                                  <v-card-text>
                                    sale: {{ item.data_device.sale_date }}
                                  </v-card-text>
                                  <v-card-text>
                                    is ours: {{ item.data_device.is_ours }}
                                  </v-card-text>
                                  <v-card-text>
                                    customer: {{ item.data_device.customer }}
                                  </v-card-text>
                                  <v-card-text>
                                    warranty seller: {{ item.data_device.warranty_seller_date }}
                                  </v-card-text>
                                  <v-card-text>
                                    warranty customer: {{ item.data_device.warranty_customer_date }}
                                  </v-card-text>
                                </v-card>
                              </v-col>

                              <v-col class="d-flex flex-column">
                                <v-card>
                                  <v-card-title>
                                    Contacts
                                  </v-card-title>
                                  <v-card-text>
                                    name: {{ item.contact_person.pib }}
                                  </v-card-text>
                                  <v-card-text>
                                    phone: {{ item.contact_person.phone }}
                                  </v-card-text>
                                  <v-card-text>
                                    e-mail: {{ item.contact_person.email }}
                                  </v-card-text>
                                </v-card>
                              </v-col>

                              <v-col class="d-flex flex-column">
                                <v-card>
                                  <v-card-title>
                                    Incoming Files
                                  </v-card-title>
                                    <v-card-text v-for="(doc, index) in item.reasons" :key="index">
<!--                                      {{ doc.name }}-->

<!--                                      <v-btn v-if="item.number"-->
<!--                                        icon-->
<!--                                        small-->
<!--                                        class="ma-2"-->
<!--                                        :href="downloadPDF(item)"-->
<!--                                        target="_blank"-->
<!--                                      >-->

                                      <a
                                          :href="doc.path"
                                          target="_blank"
                                      >{{ doc.name }}</a>

                                    </v-card-text>
                                </v-card>
                                <v-divider class="ma10 pa10"></v-divider>
                                <v-card>
                                  <v-card-title>
                                    Warranty File
                                  </v-card-title>
                                    <v-card-text>
                                      <a
                                          :href="item.warranty_file"
                                          target="_blank"
                                      >{{ item.warranty_file }}</a>
                                    </v-card-text>
                                </v-card>
                              </v-col>

                            </v-row>
                        </v-card-text>

                      </v-card>
                    </v-tab-item>

                    <v-tab-item key="1">
                      <v-card-title>
                        <v-icon color="primary">mdi-forum-outline</v-icon>
                        <v-divider class="mx-4" vertical></v-divider>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="px-2">
                              <v-btn
                                @click="refreshTTN()"
                                :disabled = "ttn_selected.length === 0"
                                color="primary"
                              >
                                <v-icon color="white">mdi-refresh</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>Refresh TTN</span>
                        </v-tooltip>

                      </v-card-title>
                      <v-divider></v-divider>

                      <v-card-text>
                        <v-data-table
                            v-model="ttn_selected"

                            :headers="headers_ttn"
                            :items="item.ttns"

                            :items-per-page="-1"
                            :hide-default-footer="true"

                            show-select
                            single-select

                            mobile-breakpoint="0"
                            class="elevation-1"

                      ></v-data-table>
                      </v-card-text>

                    </v-tab-item>

                    <v-tab-item key="2">
                      <v-card-title>
                        <v-icon color="primary">mdi-forum-outline</v-icon>
                        <v-divider class="mx-4" vertical></v-divider>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="px-2">
<!--                              -->
<!--                              @click="dialogTTN=true"-->
                              <v-btn
                                  disabled
                                color="primary"
                              >
                                <v-icon color="white">mdi-file-arrow-up-down-outline</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>Act I</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="px-2">
<!--                              -->
<!--                              @click="dialogTTN=true"-->
                              <v-btn
                                  disabled
                                color="primary"
                              >
                                <v-icon color="white">mdi-file-arrow-up-down</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>Act II</span>
                        </v-tooltip>

                      </v-card-title>
                      <v-divider></v-divider>

                      <v-card-text>
                        <v-container fluid grid-list-md>
                          <v-layout column wrap d-flex>
                            <v-card>

                              <v-card-text row wrap class="d-flex justify-center">
                                <v-flex xs4>
                                  <v-autocomplete
                                    v-model="item.stage_id"
                                    :items="maintains"
                                    item-text="name"
                                    item-value="id"
                                    hide-selected
                                    clearable
                                    label="maintainability"
                                    prepend-icon="mdi-order-bool-descending"
                                    return-object
                                  ></v-autocomplete>
                                </v-flex>

                                <v-flex xs4>
                                  <v-text-field
                                    v-if="item.new_imei === ''"
                                    v-model="item.new_imei_new"
                                    label="new IMEI"
                                    prepend-icon="mdi-tag-edit-outline"
                                  ></v-text-field>
                                  <v-text-field
                                    v-else
                                    :value="item.new_imei"
                                    label="new IMEI"
                                    prepend-icon="mdi-tag-edit-outline"
                                    disabled
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs4>
                                  <v-checkbox
                                    v-if="item.is_warranty === false"
                                    v-model="item.is_warranty"
                                    label="replacement (warranty)">
                                  </v-checkbox>
                                  <v-checkbox
                                    v-else
                                    disabled
                                    v-model="item.is_warranty"
                                    label="replacement (warranty)">
                                  </v-checkbox>
                                </v-flex>
                                <v-flex xs4>
                                  <v-file-input
                                    v-if="item.warranty_file === null"
                                    v-model="item.warranty_file"
                                    label="warranty document">
                                  </v-file-input>
                                  <v-text-field
                                    v-else
                                    :value="item.warranty_file"
                                    label="warranty document"
                                    prepend-icon="mdi-paperclip-check"
                                    disabled
                                  ></v-text-field>
                                </v-flex>
                              </v-card-text>

                              <v-card-text>
                                <v-textarea
                                  v-model="item.supp_comments.supp_diagn"

                                  label="Діагностика (хід і результати діагностики, висновки)"

                                  counter
                                  rows="2"
                                ></v-textarea>
                              </v-card-text>

                              <v-card-text>
                                <v-textarea
                                    v-model="item.supp_comments.supp_repair"
                                    label="Ремонт (хід ремонту та використані запчастини)"

                                    counter
                                    rows="2"
                                ></v-textarea>
                              </v-card-text>

                              <v-card-text>
                                <v-textarea
                                    v-model="item.supp_comments.supp_concl"
                                    label="Заключення (результати діагностики та ремонту)"

                                    counter
                                    rows="2"
                                ></v-textarea>
                              </v-card-text>

                              <v-card-text>
                                <v-textarea
                                    v-model="item.supp_comments.supp_comm"
                                    label="Коментарі (не потрапляють в Акт)"

                                    counter
                                    rows="2"
                                ></v-textarea>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
<!--                                <v-btn text @click="close">cancel</v-btn>    saveTech -->
                                <v-btn
                                  @click="saveTech(item)"
                                  text
                                  color="green darken-3"
                                >save</v-btn>
                              </v-card-actions>

                            </v-card>
                          </v-layout>
                          <v-divider></v-divider>

                        </v-container>
                      </v-card-text>

                    </v-tab-item>

                    <v-tab-item key="3">
                      <v-card-title>
                        <v-icon color="primary">mdi-compare-remove</v-icon>
                        <v-divider class="mx-4" vertical></v-divider>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-data-table
                        :headers="headersHistory"
                        :items="item.history"
                        :footer-props="{'items-per-page-options': [5, 10, 25, -1]}"
                        :items-per-page="-1"

                        dense
                        mobile-breakpoint="0"
                        class="elevation-1"
                      ></v-data-table>

                      <v-card-text>
                          <v-container fluid grid-list-md>
                            <v-layout row wrap d-flex xs12 sm12 md12>
                              <v-card >
                                <v-card-title>
                                  Тікет виробника
                                </v-card-title>
                                <v-card-text>
                                  розміщення повідомлень від замовника і відповіді виробника, документи
                                </v-card-text>
                              </v-card>
                            </v-layout>

                          </v-container>
                        </v-card-text>

                    </v-tab-item>

                    <v-tab-item key="4">
                      <v-card-title>
                        <v-icon color="primary">mdi-forum-outline</v-icon>
                        <v-divider class="mx-4" vertical></v-divider>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-card-text>
                          <v-container fluid grid-list-md>
                            <v-layout row wrap d-flex xs12 sm12 md12>
                              <v-card >
                                <v-card-title>
                                  Історія тікета
                                </v-card-title>
                                <v-card-text>
                                  дата, користувач, дія
                                </v-card-text>
                              </v-card>
                            </v-layout>

                          </v-container>
                        </v-card-text>

                    </v-tab-item>

                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-container>
          </td>
        </template>

      </v-data-table>
    </v-card>

  </div>
</template>

<script>
export default {
  name: 'Repair',

  data() {
    return {
      overlay: false,
      load: false,
      loadObjects: false,

      selected: [],
      tickets: [],

//      contracts: [],
//      contract_selected: null,


      filter_datas: [],

      serial_selected: [],
      device_selected: [],
      contractor_selected: [],

      ttn_selected: [],

      contractors: [],

//      existed_objects: [],
//      existed_object_selected: [],

      types: [],
      type_selected: [],

      producers: [],
      producer_selected: [],

      repair_objects: [],
      devices: [],

      customer: {pib: '', phone: '', email: ''},
      ttn: {number: '', phone: '', data: {}, direction: ''},
      directions: [],

      models: [],
      model_selected: [],

      expanded: [],

      maintains: [],
      maintainability: [],

      dialogTicket: false,
      dialogDevice: false,
      dialogTTN: false,

      ttn_data: {},

//
//      dialogComment: false,
//      editedComment: {text: ''},
//
//      comment_files: [],
//      act_files: [],

      menu: false,
      picker: new Date().toISOString().substr(0, 10),

      rules: [v => 13 <= v.length || 'Min 13 characters'],


      parcel_box: [{imei: '', prod_id: '', name: ''}],


//      cart: {service: [], },
//      filter: '',

      tab: null,
      tabs: [
        { id: 1, name: 'Info', icon: 'mdi-car-outline' },
        { id: 2, name: 'TTN Datas', icon: 'mdi-forum-outline'},
        { id: 3, name: 'Tech Support', icon: 'mdi-compare-remove' },
//        { id: 4, name: 'Vend Repair', icon: 'mdi-compare-remove' },
//        { id: 5, name: 'TimeLine', icon: 'mdi-compare-remove' },
      ],

      options: {
        'page': 1,
        'itemsPerPage': 10,
        'sortBy': [],
        'sortDesc': [],
        'groupBy': [],
        'groupDesc': [],
        'mustSort': false,
        'multiSort': false
      },
      sortBy: '-id',
      sortDesc: false,
      totalTickets: 0,

      headers: [
        { text: 'id', value: 'id' },
        { text: 'date', value: 'date_create', sortable: false},   // , align: ' d-none d-md-table-cell'

        { text: 'contractor', value: 'contractor', sortable: false},

        { text: 'serial', value: 'serial_device', sortable: false },  // , align: 'center'
        { text: 'device', value: 'name_device', sortable: false },  // , align: 'center'

        { text: 'place', value: 'place', sortable: false},
        { text: 'stage', value: 'stage_name', sortable: false},

        { text: 'details', value: 'data-table-expand', sortable: false },

      ],
      headers_ttn: [
//          { text: 'id', value: 'id' },
          { text: 'dir', value: 'direction' },

          { text: 'number', value: 'number', sortable: false},
          { text: 'status', value: 'status', sortable: false},

          { text: 'count', value: 'count', sortable: false},
          { text: 'cost', value: 'cost', sortable: false},
          { text: 'payer', value: 'payer', sortable: false},

      ],

      headersHistory: [],
      headersComments: [],
      headersTicket: [],

    }
  },

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    getData() {
      let self = this;
      self.load = true;

      this.$http({
        method: 'get',
        url: 'ticket_api/tickets_repair/',

        params: {
//          contract: this.contract_selected,
//          existed_object: this.existed_object_selected,

          pagination: this.options,
        },
        data: {},
      })
      .then(function (response) {
        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.filter_datas = response.data.filter_datas;

        self.contractors = response.data.contractors;

        self.repair_objects = response.data.repair_objects;
        self.devices = response.data.devices;

        self.directions = response.data.directions;
        self.maintains = response.data.maintains;


        self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    saveTickets() {
      let self = this;
      self.load = true;

//      self.overlay = true;
//      self.dialogCheckout = false;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_repair/',

        params: {

          pagination: this.options,

        },
        data: {

          ttn: this.ttn,

          contractor_id: this.contractor_selected,

          customer: this.customer,

          parcel_box: this.parcel_box,

          method: 'create_tickets',
          action: 'tickets create',

        },
      })
      .then(function (response) {
//        self.ticket_id = response.data.new_ticket_id;
//        console.log('new_ticket_id from server:', response)  // , response.data.new_ticket_id)

//        self.cart = {pause: [], active: [], external: [], internal: [], create: [], delete: [], rename: [], resims: [], }
//
//        self.saveFiles();
//
//        self.contracts_data = response.data.contracts_data;
//        self.contract_filters = response.data.contract_filters;
//
//        self.dialogCheckout = false;

        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self. dialogTicket = false;

        self.overlay = false;
        self.load = false;


      })
      .catch(function (error) {
        console.log(error);
      })
    },

    saveTicketsFiles() {
      const self = this;
      let formData = new FormData();

// files
        for (const [index_box, element] of this.parcel_box.entries()) {
          for (let [index_file, file] of element.file.entries()) {
            formData.append(
                'files', file,
                String(index_box)+'_'+String(index_file)+'__' + file.name);  //  + String(index)
          }
        }


        formData.append('ttn', JSON.stringify(this.ttn));
        formData.append('contractor_id', JSON.stringify(this.contractor_selected));
        formData.append('customer', JSON.stringify(this.customer));

        formData.append('parcel_box', JSON.stringify(this.parcel_box));

        formData.append('method', 'create_tickets');
        formData.append('action', 'tickets create');

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_repair/',

        params: {
          pagination: this.options,

        },
        data: formData,
//        data: {
//
//          ttn: this.ttn,
//
//          contractor_id: this.contractor_selected,
//
//          customer: this.customer,
//
//          parcel_box: this.parcel_box,
//
//          method: 'create_tickets',
//          action: 'tickets create',
//
//        },
      })
      .then(function (response) {
//        self.ticket_id = response.data.new_ticket_id;
//        console.log('new_ticket_id from server:', response)  // , response.data.new_ticket_id)

//        self.cart = {pause: [], active: [], external: [], internal: [], create: [], delete: [], rename: [], resims: [], }
//
//        self.saveFiles();
//
//        self.contracts_data = response.data.contracts_data;
//        self.contract_filters = response.data.contract_filters;
//
//        self.dialogCheckout = false;

        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.ttn = {number: '', phone: '', data: {}, direction: ''};
        self.parcel_box = [{imei: '', prod_id: '', name: ''}];
        self.contractor_selected = [];
        self.customer = {pib: '', phone: '', email: ''};

        self. dialogTicket = false;

        self.overlay = false;
        self.load = false;


      })
      .catch(function (error) {
        console.log(error);
      })
    },

    refreshTTN() {
      let self = this;
//      self.load = true;
      self.overlay = true;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_repair/',

        params: {
          pagination: this.options,

        },
        data: {

          ttn: this.ttn_selected,
//          tickets: this.selected,

          method: 'refresh_ttn',
          action: 'ttn refresh',

        },
      })
      .then(function (response) {

        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

//        self.dialogTTN = false;

        self.overlay = false;
//        self.load = false;


      })
      .catch(function (error) {
        console.log(error);
      })
    },

    searchTTN() {
      console.log('searchTTN')

      let self = this;
      self.load = true;
      self.overlay = true;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_repair/',

        params: {
          pagination: this.options,

        },
        data: {
          number: this.ttn.number,
          phone: this.ttn.phone,

          method: 'get_ttn_data',
          action: 'ttn get data',

        },
      })
      .then(function (response) {
        self.ttn.data = response.data.ttn_data;

        console.log('self.ttn.data', self.ttn.data);

        self.overlay = false;
        self.load = false;

      })
      .catch(function (error) {
        console.log(error);
      })
    },

    saveTTN() {
      let self = this;
      self.load = true;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_repair/',

        params: {

          pagination: this.options,

        },
        data: {

          ttn: this.ttn,
          tickets: this.selected,

          method: 'create_ttn',
          action: 'ttn create',

        },
      })
      .then(function (response) {

        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.dialogTTN = false;

        self.overlay = false;
        self.load = false;


      })
      .catch(function (error) {
        console.log(error);
      })
    },

    saveTech(item) {
      const self = this;
      self.overlay = true;

      let formData = new FormData();

      formData.append('ticket_id', JSON.stringify(item.id));

      formData.append('stage', JSON.stringify(item.stage_id));
      formData.append('new_imei', JSON.stringify(item.new_imei_new));
      formData.append('warr', JSON.stringify(item.is_warranty));
      
      formData.append('files', item.warranty_file);

      formData.append('diagn', JSON.stringify(item.supp_comments.supp_diagn));
      formData.append('repair', JSON.stringify(item.supp_comments.supp_repair));
      formData.append('concl', JSON.stringify(item.supp_comments.supp_concl));
      formData.append('comm', JSON.stringify(item.supp_comments.supp_comm));



      formData.append('method', 'save_tech');
      formData.append('action', 'tech save');

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_repair/',

        params: {
          pagination: this.options,

        },
        data: formData,
//        data: {
//
//          ttn: this.ttn,
//
//          contractor_id: this.contractor_selected,
//
//          customer: this.customer,
//
//          parcel_box: this.parcel_box,
//
//          method: 'create_tickets',
//          action: 'tickets create',
//
//        },
      })
      .then(function (response) {
        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.overlay = false;

      })
      .catch(function (error) {
        console.log(error);
      })
    },

    // методи додавання/видалення пристроїв
    addDevice(value, prodRow) {
        prodRow.push({
//          id: '*' + Math.floor(Math.random()*1000),
//          id: '',

//          name: '',
          imei: '',

//          phone_1: '',
//          phone_2: '',

//          last: '',

        });
      },

    removeDevice(index, prodRow) {
      prodRow.splice(index, 1);
    },

    close() {
      this.dialogTicket = false;
      this.dialogDevice = false;
      this.dialogTTN = false;

      this.ttn = {number: '', phone: '', data: {}, direction: ''};
      this.parcel_box = [{imei: '', prod_id: '', name: ''}];
      this.contractor_selected = [];
      this.customer = {pib: '', phone: '', email: ''};

    },

  },

  computed: {
//    cartQty() {
//        return this.cart.service.length
//    },

  },

  watch: {
    parcel_box: function() {
      console.log('parcel_box:', this.parcel_box);
    },

    ttn: function() {
      console.log('ttn:', this.ttn);
    },

    options: {
      handler () {
        this.getData();
      },
      deep: true,
    },

    expanded: function() {
      console.log('expanded:', this.expanded)
    }

  },


}
</script>

<style scoped>

</style>
