var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"repair_accounts"},[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"max-width":"800px","persistent":""},model:{value:(_vm.dialogAccount),callback:function ($$v) {_vm.dialogAccount=$$v},expression:"dialogAccount"}},[_c('v-container',[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',[_vm._v("Edit Account Data")])]),_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-layout',{staticClass:"d-flex justify-center",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs5":""}},[_c('v-text-field',{attrs:{"label":"Old serial","prepend-icon":"mdi-tag-edit-outline","disabled":""},model:{value:(_vm.account_edited.old_serial),callback:function ($$v) {_vm.$set(_vm.account_edited, "old_serial", $$v)},expression:"account_edited.old_serial"}})],1),_c('v-flex',{attrs:{"xs5":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Sent","prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.account_edited.date_sent),callback:function ($$v) {_vm.$set(_vm.account_edited, "date_sent", $$v)},expression:"account_edited.date_sent"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_sent),callback:function ($$v) {_vm.menu_sent=$$v},expression:"menu_sent"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu_sent = false}},model:{value:(_vm.account_edited.date_sent),callback:function ($$v) {_vm.$set(_vm.account_edited, "date_sent", $$v)},expression:"account_edited.date_sent"}})],1)],1)],1),_c('v-layout',{staticClass:"d-flex justify-center",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs5":""}},[_c('v-text-field',{attrs:{"label":"New serial","prepend-icon":"mdi-tag-edit-outline"},model:{value:(_vm.account_edited.new_serial),callback:function ($$v) {_vm.$set(_vm.account_edited, "new_serial", $$v)},expression:"account_edited.new_serial"}})],1),_c('v-flex',{attrs:{"xs5":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Received","prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.account_edited.date_received),callback:function ($$v) {_vm.$set(_vm.account_edited, "date_received", $$v)},expression:"account_edited.date_received"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_received),callback:function ($$v) {_vm.menu_received=$$v},expression:"menu_received"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu_received = false}},model:{value:(_vm.account_edited.date_received),callback:function ($$v) {_vm.$set(_vm.account_edited, "date_received", $$v)},expression:"account_edited.date_received"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("cancel")]),_c('v-btn',{attrs:{"text":"","color":"green darken-3"},on:{"click":function($event){return _vm.saveAccount()}}},[_vm._v("save")])],1)],1)],1)],1),_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v(_vm._s(_vm.$route.meta.icon))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"px-2"},'div',attrs,false),on),[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.account_selected.length !== 1},on:{"click":function($event){return _vm.editAccount(_vm.account_selected[0])}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-pencil-outline")])],1)],1)]}}])},[_c('span',[_vm._v("Edit Account")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"px-2"},'div',attrs,false),on),[_c('v-btn',{attrs:{"color":"primary","disabled":""},on:{"click":_vm.downloadReport}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-table-arrow-down")])],1)],1)]}}])},[_c('span',[_vm._v("Report Download")])]),_c('v-spacer'),_c('v-flex',{attrs:{"xs2":"","lg2":"","xl2":""}},[_c('v-autocomplete',{attrs:{"items":_vm.filter_datas.sent,"item-text":"name","item-value":"id","hide-selected":"","clearable":"","label":"sent","prepend-icon":"mdi-cube-send"},model:{value:(_vm.filter_sent_selected),callback:function ($$v) {_vm.filter_sent_selected=$$v},expression:"filter_sent_selected"}})],1),_c('v-flex',{attrs:{"xs2":"","lg2":"","xl2":""}},[_c('v-autocomplete',{attrs:{"items":_vm.filter_datas.received,"item-text":"name","item-value":"id","hide-selected":"","clearable":"","label":"received","prepend-icon":"mdi-cube-scan"},model:{value:(_vm.filter_received_selected),callback:function ($$v) {_vm.filter_received_selected=$$v},expression:"filter_received_selected"}})],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.accounts,"show-select":"","single-select":"","options":_vm.options,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"server-items-length":_vm.totalAccounts,"loading":_vm.load,"loading-text":"Accounts loading... Please wait","footer-props":{'items-per-page-options': [10, 25, 50]},"mobile-breakpoint":"0"},on:{"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.warranty_count",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item.warranty_count > 0)?_c('v-icon',[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-window-close")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.warranty_count))])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate",staticStyle:{"max-width":"90px"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]}}]),model:{value:(_vm.account_selected),callback:function ($$v) {_vm.account_selected=$$v},expression:"account_selected"}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","height":"2"}})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }