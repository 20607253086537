var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"repair_orders"},[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogMKTUOrder),callback:function ($$v) {_vm.dialogMKTUOrder=$$v},expression:"dialogMKTUOrder"}},[_c('v-container',[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',[_vm._v("New MKTU Order(s)")])]),_c('v-card',[_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-autocomplete',{attrs:{"label":"contract","items":_vm.contracts,"item-value":"id","item-text":"name_complete","auto-select-first":"","clearable":"","prepend-icon":"mdi-file-cog-outline"},model:{value:(_vm.contract_selected),callback:function ($$v) {_vm.contract_selected=$$v},expression:"contract_selected"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("cancel")]),_c('v-btn',{attrs:{"color":"green darken-3","text":"","disabled":_vm.contract_selected === null},on:{"click":_vm.mktuOrder}},[_vm._v("save")])],1)],1)],1)],1),_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v(_vm._s(_vm.$route.meta.icon))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"px-2"},'div',attrs,false),on),[_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":_vm.orders_selected.length < 1 || _vm.orders_selected.some(function (elem) { return elem.number === ''; })},on:{"click":_vm.refreshOrders}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)]}}])},[_c('span',[_vm._v("Order(s) Refresh")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"px-2"},'div',attrs,false),on),[_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":_vm.orders_selected.length < 1 || _vm.orders_selected.some(function (elem) { return elem.mktu_order_number !== ''; })},on:{"click":function($event){_vm.dialogMKTUOrder = true}}},[_c('v-icon',[_vm._v("mdi-book-plus")])],1)],1)]}}])},[_c('span',[_vm._v("Create Order(s) MKTU")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"px-2"},'div',attrs,false),on),[_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":_vm.orders_selected.length < 1 || _vm.orders_selected.some(function (elem) { return elem.mktu_order_number === ''; }) || _vm.orders_selected.some(function (elem) { return elem.mktu_act_number !== ''; })},on:{"click":_vm.mktuAct}},[_c('v-icon',[_vm._v("mdi-book-plus-outline")])],1)],1)]}}])},[_c('span',[_vm._v("Create Act(s) MKTU")])])],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders,"show-select":"","show-expand":"","single-expand":"","expanded":_vm.expanded,"options":_vm.options,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"server-items-length":_vm.totalOrders,"loading":_vm.load,"loading-text":"Orders loading... Please wait","footer-props":{'items-per-page-options': [10, 25, 50]},"mobile-breakpoint":"0"},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.o_pdf",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"ma-2",attrs:{"icon":"","small":"","href":_vm.downloadPDF(item, 'order'),"target":"_blank"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-file-eye ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.file_name_order))])])]}},{key:"item.a_pdf",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item.mktu_act_number)?_c('v-btn',{staticClass:"ma-2",attrs:{"icon":"","small":"","href":_vm.downloadPDF(item, 'act'),"target":"_blank"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-file-eye-outline ")])],1):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.file_name_act))])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-biohazard")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1),_c('v-data-table',{attrs:{"headers":_vm.headersTickets,"items":item.data,"item-key":item.id,"hide-default-footer":true}})],1)],1)],1)]}}]),model:{value:(_vm.orders_selected),callback:function ($$v) {_vm.orders_selected=$$v},expression:"orders_selected"}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","height":"2"}})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }