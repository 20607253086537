<template>
  <div class="repair_orders">

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialogMKTUOrder" max-width="600px">
      <v-container>

        <v-card-title class="primary white--text">
          <span>New MKTU Order(s)</span>
        </v-card-title>

        <v-card>
          <v-card-text>
            <v-layout row wrap>
<!--              <v-flex xs12>-->
                <v-autocomplete
                  v-model="contract_selected"
                  label="contract"

                  :items="contracts"

                  item-value="id"
                  item-text="name_complete"

                  auto-select-first
                  clearable

                  prepend-icon="mdi-file-cog-outline"
                ></v-autocomplete>
<!--              </v-flex>-->
            </v-layout>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn
              @click="mktuOrder"
              color="green darken-3"
              text
              :disabled = "contract_selected === null"
            >save</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                  @click="refreshOrders"
                  color="primary"
                  depressed
                  :disabled = "orders_selected.length < 1 || orders_selected.some(elem => elem.number === '')"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Order(s) Refresh</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                  @click="dialogMKTUOrder = true"
                  color="primary"
                  depressed
                  :disabled = "orders_selected.length < 1 || orders_selected.some(elem => elem.mktu_order_number !== '')"
              >
                <v-icon>mdi-book-plus</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Create Order(s) MKTU</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                  @click="mktuAct"
                  color="primary"
                  depressed
                  :disabled = "orders_selected.length < 1 || orders_selected.some(elem => elem.mktu_order_number === '') || orders_selected.some(elem => elem.mktu_act_number !== '')"
              >
                <v-icon>mdi-book-plus-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Create Act(s) MKTU</span>
        </v-tooltip>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        v-model="orders_selected"
        :headers="headers"
        :items="orders"

        show-select

        show-expand
        single-expand
        :expanded.sync="expanded"

        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"

        :server-items-length="totalOrders"

        :loading="load"
        loading-text="Orders loading... Please wait"

        :footer-props="{'items-per-page-options': [10, 25, 50]}"

        mobile-breakpoint="0"
        class="elevation-1">

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>


        <template v-slot:item.o_pdf="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
<!--                 v-if="item.mktu_order_number"-->
                <v-btn
                  icon
                  small

                  class="ma-2"
                  :href="downloadPDF(item, 'order')"
                  target="_blank"
                >
                  <v-icon dark>
                    mdi-file-eye
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ item.file_name_order }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.a_pdf="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn v-if="item.mktu_act_number"
                  icon
                  small

                  class="ma-2"
                  :href="downloadPDF(item, 'act')"
                  target="_blank"
                >
                  <v-icon dark>
                    mdi-file-eye-outline
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ item.file_name_act }}</span>
          </v-tooltip>
        </template>


        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan='headers.length'>

            <v-container>
              <v-card>

                <v-card-title>
                  <v-icon color="primary">mdi-biohazard</v-icon>
                  <v-divider class="mx-4" inset vertical></v-divider>

                </v-card-title>

                <v-data-table
                  :headers="headersTickets"
                  :items="item.data"
                  :item-key="item.id"
                  :hide-default-footer="true"
                ></v-data-table>

              </v-card>
            </v-container>

          </td>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
export default {
  name: 'RepairOrders',

  data() {
    return {
      overlay: false,
      load: false,

      orders: [],
      totalOrders: 0,

      orders_selected: [],

      contracts: [],
      contract_selected: null,

      options: {
        'page': 1,
        'itemsPerPage': 10,
        'sortBy': [],
        'sortDesc': [],
        'groupBy': [],
        'groupDesc': [],
        'mustSort': false,
        'multiSort': false
      },
      sortBy: '-id',
      sortDesc: false,

      expanded: [],

      dialogMKTUOrder: false,

      headers: [
        { text: 'id', value: 'id', sortable: false },

        { text: 'o_uk', value: 'number', sortable: false },
        { text: 'o_mktu', value: 'mktu_order_number', sortable: false },
        { text: 'o_pdf', value: 'o_pdf', sortable: false },

        { text: 'a_uk', value: 'uk_act_number', sortable: false },
        { text: 'a_mktu', value: 'mktu_act_number', sortable: false },
        { text: 'a_pdf', value: 'a_pdf', sortable: false },

        { text: 'count', value: 'tickets', sortable: false },
        { text: 'amound', value: 'amount', sortable: false },
        { text: 'paid', value: 'paid', sortable: false },

        { text: 'content', value: 'data-table-expand', sortable: false },

      ],
      headersTickets: [
        { text: 'id', value: 'id', sortable: false },

        { text: 'contractor', value: 'contractor', sortable: false },
        { text: 'serial', value: 'serial', sortable: false },
        { text: 'device', value: 'device', sortable: false },

        { text: 'payment status', value: 'payment', sortable: false },

      ]
    }
  },

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    getData() {
      let self = this;
      self.load = true;

      this.$http({
        method: 'get',
        url: 'ticket_api/tickets_orders/',

        params: {
          pagination: this.options,

        },
        data: {},
      })
      .then(function (response) {
        self.orders = response.data.orders;
        self.totalOrders = response.data.orders_count;

        self.contracts = response.data.mktu_contracts;

        self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    refreshOrders() {
      let self = this;
      self.load = true;
      self.overlay = true;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_orders/',

        params: {
          pagination: this.options,

        },
        data: {
          orders: Array.from(this.orders_selected, x => x.id),

          method: 'refresh_orders',
          action: 'orders refresh',
        },
      })
      .then(function (response) {
        self.orders = response.data.orders;
        self.totalOrders = response.data.orders_count;

        self.orders_selected = [];

        self.load = false;
        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    mktuOrder() {
      let self = this;
      self.load = true;
      self.overlay = true;

      self.dialogMKTUOrder = false;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_orders/',

        params: {
          pagination: this.options,

        },
        data: {
          orders: Array.from(this.orders_selected, x => x.id),
          contract: this.contract_selected,

          method: 'order_mktu',
          action: 'mktu order',
        },
      })
      .then(function (response) {
        self.orders = response.data.orders;
        self.totalOrders = response.data.orders_count;

        self.orders_selected = [];
        self.contract_selected = null;

        self.load = false;
        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    mktuAct() {
      let self = this;
      self.load = true;
      self.overlay = true;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_orders/',

        params: {
          pagination: this.options,

        },
        data: {
          orders: Array.from(this.orders_selected, x => x.id),

          method: 'act_mktu',
          action: 'mktu act',
        },
      })
      .then(function (response) {
        self.orders = response.data.orders;
        self.totalOrders = response.data.orders_count;

        self.orders_selected = [];

        self.load = false;
        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    downloadPDF(item, type) {
      let result;

      if (type === 'order') {
        result = 'core_api/get_pdf_file/repair_orders_pdf/'+item.date_create_order_uk+'/'+item.file_name_order;
      } else {
        result = 'core_api/get_pdf_file/repair_acts_pdf/'+item.date_create_act_mktu+'/'+item.file_name_act;
      }

      return result;
//      return 'core_api/get_pdf_file/orders_pdf/'+item.date_create+'/'+item.file_name
    },

    close() {
      this.dialogMKTUOrder = false;

      this.contract_selected = null;

    },

  },

  computed: {},

  watch: {
    options: {
      handler () {
        this.getData();
      },
      deep: true,
    },

  },

}
</script>

<style scoped>

</style>