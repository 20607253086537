<template>
  <div class="repair_devices">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialogDevice" max-width="800px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>Edit Device Data</span>
        </v-card-title>
        <v-card>

          <v-card-text>
          <v-container>
            <v-layout row wrap class="d-flex justify-center">

              <v-flex xs4>
                <v-text-field
                  v-model="device_edited.serial"
                  label="Serial"
                  prepend-icon="mdi-tag-edit-outline"
                ></v-text-field>
              </v-flex>
              <v-flex xs5>
                <v-autocomplete
                  v-model="device_edited.device_id"
                  :items="models"
                  item-text="name"
                  item-value="id"
                  hide-selected

                  label="Model"
                  prepend-icon="mdi-developer-board"
                  return-object
                ></v-autocomplete>
              </v-flex>

            </v-layout>
            <v-divider class="ma-7" inset></v-divider>

            <v-layout row wrap class="d-flex justify-center">

              <v-flex xs1>
                <v-checkbox
                  v-model="device_edited.is_ours"
                  label="our">
                </v-checkbox>
              </v-flex>

              <v-flex xs4>
                <v-menu
                  v-model="menu_buy"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="device_edited.purchase_date"
                      label="Buy"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="device_edited.purchase_date"
                    @input="menu_buy = false"

                  ></v-date-picker>
                </v-menu>
              </v-flex>


            </v-layout>
            <v-divider class="ma-7" inset></v-divider>

            <v-layout row wrap class="d-flex justify-center">

              <v-flex xs4>
                <v-menu
                  v-model="menu_sell"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="device_edited.sale_date"
                      label="Sell"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="device_edited.sale_date"
                    @input="menu_sell = false"

                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6>
                <v-autocomplete
                  v-model="device_edited.contractor"
                  :items="contractors"
                  item-text="name"
                  item-value="id"
                  hide-selected
                  clearable
                  label="Contractor"
                  prepend-icon="mdi-file-multiple-outline"
                  return-object
                ></v-autocomplete>
              </v-flex>

            </v-layout>
            <v-divider class="ma-7" inset></v-divider>

            <v-layout row wrap class="d-flex justify-center">

              <v-flex xs4>
                <v-menu
                  v-model="menu_warranty_seller"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="device_edited.warranty_seller"
                      label="Seller warranty"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="device_edited.warranty_seller"
                    @input="menu_warranty_seller = false"

                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs4>
                <v-menu
                  v-model="menu_warranty_customer"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="device_edited.warranty_customer"
                      label="Customer warranty"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="device_edited.warranty_customer"
                    @input="menu_warranty_customer = false"

                  ></v-date-picker>
                </v-menu>
              </v-flex>

            </v-layout>
            <v-divider class="ma-7" inset></v-divider>

            <v-layout row wrap class="d-flex justify-center">

              <v-flex xs4>
                <v-autocomplete
                  v-model="device_edited.prev_id"
                  :items="repair_objs"
                  item-text="serial"
                  item-value="id"
                  hide-selected
                  clearable
                  label="prev device"
                  prepend-icon="mdi-arrow-left-thick"
                  return-object
                ></v-autocomplete>
              </v-flex>

              <v-flex xs4>
                <v-autocomplete
                  v-model="device_edited.next_id"
                  :items="repair_objs"
                  item-text="serial"
                  item-value="id"
                  hide-selected
                  clearable
                  label="next device"
                  prepend-icon="mdi-arrow-right-thick"
                  return-object
                ></v-autocomplete>
              </v-flex>

            </v-layout>

          </v-container>
        </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">cancel</v-btn>
          <v-btn
              text
              @click="saveDevice()"
              color="green darken-3"
          >save</v-btn>
        </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

<!--Buttons-->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                @click="editDevice(device_selected[0])"
                color="primary"
                :disabled="device_selected.length !== 1"
              >
                <v-icon color="white">mdi-pencil-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Edit Device</span>
        </v-tooltip>

        <v-spacer></v-spacer>

<!--Filters-->
        <v-flex xs3 lg3 xl3>
          <v-autocomplete
            v-model="filter_selected_model"
            :items="filter_datas.models"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="model"
            prepend-icon="mdi-developer-board"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs3 lg3 xl3>
          <v-autocomplete
            v-model="filter_selected_contractor"
            :items="filter_datas.contractors"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="contractor"
            prepend-icon="mdi-file-multiple-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="filter_selected_ours"
            :items="filter_datas.is_ours"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="is_our"
            prepend-icon="mdi-check-all"
          ></v-autocomplete>
        </v-flex>


      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        v-model="device_selected"
        :items="devices"
        :headers="headers"

        show-select
        single-select

        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"

        :server-items-length="totalDevices"

        :loading="load"
        loading-text="Repair Devices loading... Please wait"

        :footer-props="{'items-per-page-options': [10, 25, 50]}"

        mobile-breakpoint="0"
        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:item.is_ours="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.is_ours">mdi-check</v-icon>
                <v-icon v-else>mdi-window-close</v-icon>
              </div>
            </template>
            <span>{{ item.is_ours }}</span>
          </v-tooltip>
      </template>
        <template v-slot:item.device_name="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                  class="text-truncate"
                  style="max-width: 90px;"
                  v-bind="attrs"
                  v-on="on"
              >
                {{ item.device_name }}
              </div>
            </template>
            <span>{{ item.device_name }}</span>
          </v-tooltip>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
export default {
  name: 'Devices',

  data() {
    return {
      overlay: false,
      load: false,

      contractors: [],
      contractor_selected: [],

      models: [],
      model_selected: [],

      devices: [],
      device_selected: [],
//      device_selected: [
//          {
//            serial: '',
//            device_id: 0,
//
//            purchase_date: '',
//            sale_date: '',
//
//            is_ours: false,
//
//            next: {id: 0, name: ''},
//            prev: {id: 0, name: ''}
//
//          }, {}],
      devices_count: 0,

      device_edited: [
          {
            serial: '',
            device_id: 0,

            purchase_date: '',
            sale_date: '',

            warranty_seller: '',
            warranty_customer: '',

            is_ours: true,

            contractor_id: 0,
            contractor_name: '',
            contractor: {},

            prev_id: 0,
            prev_serial: '',

            next_id: 0,
            next_serial: '',

          }, {}],

      repair_objs: [],

      filter_datas: {},
      filter_selected_ours: [],
      filter_selected_model: [],
      filter_selected_contractor: [],

      dialogDevice: false,

//      menu: false,
//      picker: new Date().toISOString().substr(0, 10),
//
//      rules: [v => 13 <= v.length || 'Min 13 characters'],

      options: {
        'page': 1,
        'itemsPerPage': 10,
        'sortBy': [],
        'sortDesc': [],
        'groupBy': [],
        'groupDesc': [],
        'mustSort': false,
        'multiSort': false
      },

      sortBy: '-id',
      sortDesc: false,

      totalDevices: 0,

      menu_buy: false,
      menu_sell: false,
      menu_warranty_seller: false,
      menu_warranty_customer: false,

      picker_buy: new Date().toISOString().substr(0, 10),
      picker_sell: new Date().toISOString().substr(0, 10),

      headers: [
        { text: 'id', value: 'id' },

        { text: 'serial', value: 'serial', sortable: false },
        { text: 'device', value: 'device_name', sortable: false },

        { text: 'our', value: 'is_ours', sortable: false },

        { text: 'purch', value: 'purchase_date', sortable: false},
        { text: 'sale', value: 'sale_date', sortable: false},

        { text: 'contractor', value: 'contractor_name', sortable: false},

        { text: 'w_seller', value: 'warranty_seller', sortable: false},
        { text: 'w_customer', value: 'warranty_customer', sortable: false},

        { text: 'prev', value: 'prev_serial', sortable: false},
        { text: 'next', value: 'next_serial', sortable: false},

      ],

    }
  },

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    getData() {
      let self = this;
      self.load = true;

      this.$http({
        method: 'get',
        url: 'ticket_api/tickets_devices/',

        params: {
          filter_selected_ours: this.filter_selected_ours,
          filter_selected_model: this.filter_selected_model,
          filter_selected_contractor: this.filter_selected_contractor,

          pagination: this.options,

        },
        data: {},
      })
      .then(function (response) {
        self.devices = response.data.devices;
        self.totalDevices = response.data.devices_count;

        self.models = response.data.models;
        self.contractors = response.data.contractors;
        self.repair_objs = response.data.repair_objs;

        self.filter_datas = response.data.filters_data;

        self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    editDevice(item) {
        this.device_edited = Object.assign({}, item);

        this.dialogDevice = true;
      },

    saveDevice() {
        const self = this;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'ticket_api/tickets_devices/',

          params: {
            pagination: this.options,

          },
          data: {
            device: this.device_edited,

            method: 'edit_device',
            action: 'device edit',

          },
        })
        .then(function (response) {
          self.devices = response.data.devices;
          self.totalDevices = response.data.devices_count;

          self.repair_objs = response.data.repair_objs;

          self.device_selected = [];
          self.overlay = false;
          self.dialogDevice = false;

        })
        .catch(function (error) {
          console.log(error);
        })
      },

    close() {
      this.device_selected = [];
      this.dialogDevice = false;


//      this.objects_selected = [];
//      this.cart = {service: [], };
    },

  },

  computed: {},

  watch: {
    options: {
      handler () {
        this.getData();
      },
      deep: true,
    },

    device_selected: function () {
      console.log('device_selected:', this.device_selected)
    },
    device_edited: function () {
      console.log('device_edited:', this.device_edited)
    },

    filter_selected_model: function() {
      this.getData()
    },
    filter_selected_contractor: function() {
      this.getData()
    },
    filter_selected_ours: function() {
      this.getData()
    },

  },


}
</script>

<style scoped>

</style>
