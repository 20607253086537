<template>
  <div class="repair_accounts">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialogAccount" max-width="800px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>Edit Account Data</span>
        </v-card-title>

        <v-card>

          <v-card-text>
            <v-container>
              <v-layout row wrap class="d-flex justify-center">

                <v-flex xs5>
                  <v-text-field
                    v-model="account_edited.old_serial"
                    label="Old serial"
                    prepend-icon="mdi-tag-edit-outline"
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs5>
                  <v-menu
                    v-model="menu_sent"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="account_edited.date_sent"
                        label="Sent"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="account_edited.date_sent"
                      @input="menu_sent = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout row wrap class="d-flex justify-center">

                <v-flex xs5>
                  <v-text-field
                      v-model="account_edited.new_serial"
                      label="New serial"
                      prepend-icon="mdi-tag-edit-outline"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5>
                  <v-menu
                    v-model="menu_received"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="account_edited.date_received"
                        label="Received"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="account_edited.date_received"
                      @input="menu_received = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>

                </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn
                text
                @click="saveAccount()"
                color="green darken-3"
            >save</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

<!--Buttons-->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                @click="editAccount(account_selected[0])"
                color="primary"
                :disabled="account_selected.length !== 1"
              >
                <v-icon color="white">mdi-pencil-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Edit Account</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="px-2">
                <v-btn
                  @click="downloadReport"
                  color="primary"
                  disabled
                >
                  <v-icon color="white">mdi-table-arrow-down</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Report Download</span>
          </v-tooltip>
        <v-spacer></v-spacer>

<!--Filters-->
      <v-flex xs2 lg2 xl2>
        <v-autocomplete
          v-model="filter_sent_selected"
          :items="filter_datas.sent"
          item-text="name"
          item-value="id"
          hide-selected
          clearable
          label="sent"
          prepend-icon="mdi-cube-send"
        ></v-autocomplete>
      </v-flex>
      <v-flex xs2 lg2 xl2>
        <v-autocomplete
          v-model="filter_received_selected"
          :items="filter_datas.received"
          item-text="name"
          item-value="id"
          hide-selected
          clearable
          label="received"
          prepend-icon="mdi-cube-scan"
        ></v-autocomplete>
      </v-flex>

      </v-card-title>

      <v-divider></v-divider>

      <v-data-table
        v-model="account_selected"

        :headers="headers"
        :items="accounts"

        show-select
        single-select

        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"

        :server-items-length="totalAccounts"

        :loading="load"
        loading-text="Accounts loading... Please wait"

        :footer-props="{'items-per-page-options': [10, 25, 50]}"

        mobile-breakpoint="0"
        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:item.warranty_count="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.warranty_count > 0">mdi-check</v-icon>
                <v-icon v-else>mdi-window-close</v-icon>
              </div>
            </template>
            <span>{{ item.warranty_count }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.name="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                  class="text-truncate"
                  style="max-width: 90px;"
                  v-bind="attrs"
                  v-on="on"
              >
                {{ item.name }}
              </div>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </template>


      </v-data-table>

    </v-card>

  </div>
</template>

<script>
export default {
  name: 'Accounts',

  data() {
    return {
      overlay: false,
      load: false,

      accounts: [],

      account_selected: [],
      account_edited: [],

      filter_datas: [],
      filter_sent_selected: [],
      filter_received_selected: [],

      dialogAccount: false,

      menu_sent: false,
      menu_received: false,

//      picker: new Date().toISOString().substr(0, 10),
//      rules: [v => 13 <= v.length || 'Min 13 characters'],

      options: {
        'page': 1,
        'itemsPerPage': 10,
        'sortBy': [],
        'sortDesc': [],
        'groupBy': [],
        'groupDesc': [],
        'mustSort': false,
        'multiSort': false
      },

      sortBy: '-id',
      sortDesc: false,

      totalAccounts: 0,

      headers: [
        { text: 'id', value: 'id' },
        { text: 'ticket', value: 'ticket_id' },
        { text: 'vend', value: 'ticket_vendor_id', sortable: false },
        { text: 'w_files', value: 'warranty_count', sortable: false },

        { text: 'device', value: 'name', sortable: false },

        { text: 'old', value: 'old_serial', sortable: false },
        { text: 'sent', value: 'date_sent' },

        { text: 'substitute', value: 'substitute_serial', sortable: false },

        { text: 'new', value: 'new_serial', sortable: false },
        { text: 'received', value: 'date_received' },

      ],

    }
  },

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    getData() {
      let self = this;
      self.load = true;

      this.$http({
        method: 'get',
        url: 'ticket_api/tickets_accounting/',

        params: {
          filter_sent_selected: this.filter_sent_selected,
          filter_received_selected: this.filter_received_selected,

          pagination: this.options,

        },
        data: {},
      })
      .then(function (response) {
        self.accounts = response.data.accounts;
        self.totalAccounts = response.data.accounts_count;

        self.filter_datas = response.data.filter_datas;

        self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    editAccount(item) {
        this.account_edited = Object.assign({}, item);

        this.dialogAccount = true;
      },

    saveAccount() {
      const self = this;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'ticket_api/tickets_accounting/',

          params: {
            pagination: this.options,

            filter_sent_selected: this.filter_sent_selected,
            filter_received_selected: this.filter_received_selected,

          },
          data: {
            account: this.account_edited,

            method: 'edit_account',
            action: 'account edit',

          },
        })
        .then(function (response) {
          self.accounts = response.data.accounts;
          self.totalAccounts = response.data.accounts_count;

          self.account_selected = [];

          self.dialogAccount = false;
          self.overlay = false;

        })
        .catch(function (error) {
          console.log(error);
        })
    },

    downloadReport() {
      let self = this;
      self.overlay = true;

      this.$http({
        method: 'get',
        url: 'ticket_api/report_accounting_repair/',
        responseType: 'blob',

        params: {
          filter_sent_selected: this.filter_sent_selected,
          filter_received_selected: this.filter_received_selected,

          pagination: this.options,

        },
        data: {},
      })
      .then(function (response) {

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const fileName = 'report_accounting' + '' + '.xlsx';

        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        self.overlay = false;

      })
      .catch(function (error) {
        console.log(error);
      })

    },

    close() {
      this.dialogAccount = false;


//      this.objects_selected = [];
//      this.cart = {service: [], };
    },

  },

  computed: {},

  watch: {
    filter_sent_selected: function() {
      this.getData()
    },
    filter_received_selected: function() {
      this.getData()
    },

    options: {
      handler () {
        this.getData();
      },
      deep: true,
    },

  },


}
</script>

<style scoped>

</style>
